import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { GlobalContainer,
         TitleContainer,
         Title,
         SubTitleContainer,
         SubTitle,
         Text,
         ContentContainer,
         ContentContainer1,
         ContentContainer11,
         ContentContainer12,
         ContentContainer2,
         ContentContainer21,
         ContentContainer22,
         DescContainer,
         TextDesc,
         ContentContainerButtons } from './styles';
import MessageAlert from '../../Components/Messages/Alert/index';
import ModalConfirm from '../../Components/Modal/Confirm/index';
import ModalCancel from '../../Components/Modal/Cancel/index';
import ModalError from '../../Components/Modal/Warn/index';
import ModalWarn from '../../Components/Modal/Warn/index'

//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

import InfoIcon from '../../assets/info_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import { cpfMask } from '../../utils';

const cellMask = (cell) => {
    var num = cell?.replace(/[^\d]/g, ''); //remove todos os caracteres não numéricos
    var len = num?.length; //guarda o número de digitos até o momento

    if(len <= 2){
        cell = num?.replace(/(\d{1,2})/g, '($1');
    }else if(len <= 7){
        cell = num?.replace(/(\d{2})(\d{0,5})/g, '($1) $2');
    }else{
        cell = num?.replace(/(\d{2})(\d{5})(\d{1,4})/g, '($1) $2-$3');
    }
    return cell;
}

export default function CitizenData(props) {
    const [loadCitizen, setLoadCitizen] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const [ modalError, setModalError ] = useState(false);
    const [ modalErrorFinish, setModalErrorFinish ] = useState(false);
    // Para exibir mensagens de erro do Acesso
    const [ modalErrorAcesso, setModalErrorAcesso ] = useState(false);
    const [ textError, setTextError ] = useState('');
    
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    const [citizen, setCitizen] = useState({
        id_attendance: '',
        attendent: '',
        cpf_attendant: '',
        person: '',
        ident_dig: '',
        cpf: '',
        name: '',
        social_name: '',
        birth_date: '',
        mothers_name: '',
        email: '',
        cell: '',
        documents: '',
        document_list: '',
        document_type: '',
        last_id_list: ''
    });

    const [attorney, setAttorney] = useState({
        name_attorney: '',
        cpf_attorney: ''
    });

    useEffect(() => {
        if (!loadCitizen && props.location.state !== undefined) {
            setCitizen({
                id_attendance: props.location.state.id_attendance,
                attendent: props.location.state.attendent,
                cpf_attendant: props.location.state.cpf_attendant,
                person: props.location.state.person,
                ident_dig: props.location.state.ident_dig,
                cpf: props.location.state.cpf,
                selo_conta: props.location.state.selo_conta,
                name: props.location.state.name,
                social_name: props.location.state.social_name,
                birth_date: props.location.state.birth_date,
                mothers_name: props.location.state.mothers_name,
                email: props.location.state.email,
                cell: props.location.state.cell,
                documents: props.location.state.documents,
                document_list: props.location.state.document_list,
                document_type: props.location.state.document_type,
                last_id_list: props.location.state.last_id_list
            });

            setAttorney({
                name_attorney: props.location.state.name_attorney,
                cpf_attorney: props.location.state.cpf_attorney,
            });

            setLoadCitizen(true);
        }
    }, [props.location.state, props, loadCitizen]);

    const { register, handleSubmit, errors } = useForm();
    const { person, ident_dig, cpf, selo_conta, name, social_name, birth_date, mothers_name, email, cell } = citizen;

    const [ isModalConfirmVisible, setModalConfirmVisible ] = useState(false);
    const [ isModalCancelVisible, setModalCancelVisible ] = useState(false);

    const actionButtonModalConfirmCancel = () => {
        setModalConfirmVisible(false);
    };

    const actionButtonModalCancelCancel = () => {
        setModalCancelVisible(false);
    };

    const actionButtonGeneratePassword = async () => {

        setLoading(true);

        // GERAR SENHA ATENDIMENTO
        var passwordGenerate = '';

        // SE FOR FLUXO CIDADAO
        if (citizen.person === "cidadao") {
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    id_atendimento:  citizen.id_attendance,
                    nome_cidadao: citizen.name,
                    email_cidadao: citizen.email,
                    celular_cidadao: citizen.cell,
                    nome_atendente: citizen.attendent,
                    cpf_atendente: citizen.cpf_attendant,
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/atendimento/finaliza`,
                        requestOptions
            ).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                        if (data.senha_provisoria){
                            passwordGenerate = data.senha_provisoria

                            setLoading(false);
                            props.history.push(
                                "/senhaprovisoria", 
                                {id_attendance: citizen.id_attendance,
                                citizen_person: citizen.person,
                                citizen_name: citizen.name, 
                                citizen_cpf: citizen.cpf,
                                citizen_cell: citizen.cell, 
                                citizen_email: citizen.email, 
                                password: passwordGenerate,
                                ident_dig: citizen.ident_dig}
                            );  
                        } else {
                            setLoading(false);
                            setModalConfirmVisible(false);
                            setModalError(true);
                            console.log('Response error in atendimento/finaliza BackendBalcao');
                        }
                    });
                } else if (response.status === 422) {
                    //CASO O ATENDIMENTO JA ESTEJA FINALIZADO:
                    response.json().then(function(data) {
                        if (data.errorCode === "04") {
                            setLoading(false);
                            setModalConfirmVisible(false);
                            setModalErrorFinish(true);
                        }else if (data.errorCode === "12") { //exibir mensagem de erro do Acesso
                            setLoading(false);
                            setModalConfirmVisible(false);
                            setModalErrorAcesso(true);
                            //alert(data.message);
                            setTextError(data.message);
                        } else {
                            setLoading(false);
                            setModalConfirmVisible(false);
                            setModalError(true);
                            console.log('Error in atendimento/finaliza BackendBalcao: '+data.message);
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setLoading(false)
                      setModalInvalidSessionError(true)
                    })
                  } else  {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    setLoading(false);
                    setModalConfirmVisible(false);
                    setModalError(true);
                    console.log('Request error in atendimento/finaliza BackendBalcao');
                   // console.log('Request error in atendimento/finaliza BackendBalcao' + data.message);
                }
            }).catch(function(err) {
                setLoading(false);
                setModalConfirmVisible(false);
                setModalError(true);
                console.error('Failed retrieving information', err);
            });
        } 
        // FLUXO PROCURADOR
        else {
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    id_atendimento:  citizen.id_attendance,
                    cpf_cidadao: citizen.cpf,
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/atendimento/procuracao/cidadao`,
                        requestOptions
            ).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                        if (data.message){
                            
                            // AQUI O CIDADAO FOI ASSOCIADO AO PROCURADOR

                            // AGORA VAMOS ANEXAR OS DOCUMENTOS (ANEXAR REQUEST)
                            var documentsList = [];
                            var obj = {};

                            citizen.documents.forEach(doc => {
                                obj = {
                                    tipo_doc: doc.type,
                                    documento: doc.file,
                                };

                                documentsList.push(obj);
                            });

                            //console.log('Document List (Body) ->', documentsList);

                            const requestOptions = {
                                method: 'PUT',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                                }),
                                body: JSON.stringify({
                                    id_atendimento: citizen.id_attendance,
                                    documento: documentsList,
                                }),
                            };
                            
                            fetch(`${process.env.REACT_APP_API_URL}/documentos/anexa`,
                                        requestOptions
                            ).then(function(response) {
                                if (response.ok) {
                                    response.json().then(function(data) {
                                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                                        if (data.Mensagem){
                                            // ANEXOU COM SUCESSO OS DOCUMENTOS

                                            // AGORA FINALIZA O ATENDIMENTO
                                            const requestOptions = {
                                                method: 'PUT',
                                                headers: new Headers({
                                                    'Content-Type': 'application/json',
                                                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                                                }),
                                                body: JSON.stringify({
                                                    id_atendimento:  citizen.id_attendance,
                                                    nome_cidadao: citizen.name,
                                                    email_cidadao: citizen.email,
                                                    celular_cidadao: citizen.cell,
                                                    nome_atendente: citizen.attendent,
                                                    cpf_atendente: citizen.cpf_attendant,
                                                }),
                                            };
                                            fetch(`${process.env.REACT_APP_API_URL}/atendimento/finaliza`,
                                                        requestOptions
                                            ).then(function(response) {
                                                if (response.ok) {
                                                    response.json().then(function(data) {
                                                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
                                
                                                        if (data.senha_provisoria){
                                                            passwordGenerate = data.senha_provisoria
                                
                                                            setLoading(false);
                                                            props.history.push(
                                                                "/senhaprovisoria", 
                                                                {id_attendance: citizen.id_attendance,
                                                                citizen_person: citizen.person,
                                                                citizen_name: citizen.name, 
                                                                citizen_cpf: citizen.cpf,
                                                                citizen_cell: citizen.cell, 
                                                                citizen_email: citizen.email, 
                                                                password: passwordGenerate,
                                                                ident_dig: citizen.ident_dig}
                                                            );  
                                                        } else {
                                                            setLoading(false);
                                                            setModalConfirmVisible(false);
                                                            setModalError(true);
                                                            console.log('Response error in atendimento/finaliza BackendBalcao');
                                                        }
                                                    });
                                                } else if (response.status === 422) {
                                                    //CASO O ATENDIMENTO JA ESTEJA FINALIZADO
                                                    response.json().then(function(data) {
                                                        if (data.errorCode === "04") {
                                                            setLoading(false);
                                                            setModalConfirmVisible(false);
                                                            setModalErrorFinish(true);
                                                        }else if (data.errorCode === "12") { //exibir mensagem de erro do Acesso
                                                            setLoading(false);
                                                            setModalConfirmVisible(false);
                                                            setModalErrorAcesso(true);
                                                            //alert(data.message);
                                                            setTextError(data.message);
                                                        } else {
                                                            setLoading(false);
                                                            setModalConfirmVisible(false);
                                                            setModalError(true);
                                                            console.log('Error in atendimento/finaliza BackendBalcao: '+data.message);
                                                        }
                                                    });
                                                } else if (response.status === 403) {
                                                    response.json().then(function (data) {
                                                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                                                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                                                      setLoading(false)
                                                      setModalInvalidSessionError(true)
                                                    })
                                                  } else {
                                                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                                                    setLoading(false)
                                                    setModalConfirmVisible(false)
                                                    setModalError(true)
                                                    console.log('Request error in atendimento/cidadao BackendBalcao')
                                                }
                                            }).catch(function(err) {
                                                setLoading(false);
                                                setModalConfirmVisible(false);
                                                setModalError(true);
                                                console.error('Failed retrieving information', err);
                                            });                                            

                                        } else {
                                            setLoading(false);
                                            setModalConfirmVisible(false);
                                            setModalError(true);
                                            console.log('Response error in documentos/anexa BackendBalcao');
                                        }
                                    });
                                } else if (response.status === 422) {
                                    //CASO O ATENDIMENTO JA ESTEJA FINALIZADO
                                    response.json().then(function(data) {
                                        if (data.errorCode === "04") {
                                            setLoading(false);
                                            setModalConfirmVisible(false);
                                            setModalErrorFinish(true);
                                        }else if (data.errorCode === "12") { //exibir mensagem de erro do Acesso
                                            setLoading(false);
                                            setModalConfirmVisible(false);
                                            setModalErrorAcesso(true);
                                            //alert(data.message);
                                            setTextError(data.message);
                                        } else {
                                            setLoading(false);
                                            setModalConfirmVisible(false);
                                            setModalError(true);
                                            console.log('Error in documentos/anexa BackendBalcao: '+data.message);
                                        }
                                    });
                                } else if (response.status === 403) {
                                    response.json().then(function (data) {
                                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                                      setLoading(false)
                                      setModalInvalidSessionError(true)
                                    })
                                  } else {
                                        // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                                        setLoading(false);
                                        setModalConfirmVisible(false);
                                        setModalError(true);
                                        console.log('Request error in documentos/anexa BackendBalcao');
                                }
                            }).catch(function(err) {
                                setLoading(false);
                                setModalConfirmVisible(false);
                                setModalError(true);
                                console.error('Failed retrieving information', err);
                            });
                        } else {
                            setLoading(false);
                            setModalConfirmVisible(false);
                            setModalError(true);
                            console.log('Response error in atendimento/procuracao/cidadao BackendBalcao');
                        }
                    });
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    setLoading(false);
                    setModalConfirmVisible(false);
                    setModalError(true);
                    console.log('Request error in atendimento/procuracao/cidadao BackendBalcao');
                }
            }).catch(function(err) {
                setLoading(false);
                setModalConfirmVisible(false);
                setModalError(true);
                console.error('Failed retrieving information', err);
            });
        }
    };

    const removeInputMask = (cell) => {
        if (cell === "") {
            return ""
        } else {
            return cell.replace(/[^\d]+/g, "");
        }
    };

    const onSubmit = (data) => {

        //console.log(data);
        //console.log(citizen);

        data.cell = removeInputMask(data.cell);

        setCitizen({
            ...citizen, 
            email: data.email,
            cell: data.cell
        });

        setModalConfirmVisible(true);
    };

    return (
        <>
            {!loadCitizen ?
                <div style={{'marginTop': '75px'}}><h1> Error on the page! </h1></div>
            :
                <>
                    <ModalConfirm 
                        id="modalconfirmalert"
                        appear={isModalConfirmVisible}
                        title={
                            person === "cidadao" ?
                                "Confirmar Identificação do Cidadão"
                            :
                                "Confirmar Identificação do Procurador"
                        }
                        text={
                            person === "cidadao" ?
                                "Confirmo que identifiquei o cidadão devidamente, mediante apresentação de documento original de identificação válido e oficial."
                            :
                                "Confirmo que identifiquei devidamente o procurador, mediante apresentação da procuração assinada e documento original de identificação válido e oficial."
                        }
                        textButton1={"Cancelar"}
                        textButton2={"Confirmar"}
                        actionButton1={actionButtonModalConfirmCancel}
                        load={loading}
                        actionButton2={actionButtonGeneratePassword}
                    />

                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja cancelar o atendimento?"}
                        actionButton1={actionButtonModalCancelCancel}
                        actionButton2={() => props.history.push("/pesquisarcidadao")}
                    />

                    <ModalError
                        id="modalconfirmalert"
                        appear={modalError}
                        text = 'Houve algum problema no servidor. Aguarde um momento e tente novamente.'
                        textButton2='Ok'
                        actionButton2={() => setModalError(false)}
                    />

                    <ModalError
                        id="modalconfirmalert"
                        appear={modalErrorAcesso}
                        text = {textError}
                        textButton2='Ok'
                        actionButton2={() => setModalErrorAcesso(false)}
                    />

                    <ModalError
                        id="modalconfirmalert"
                        appear={modalErrorFinish}
                        text = 'Este atendimento já foi finalizado. Todo atendimento deve ser iniciado da Página Inicial.'
                        textButton2='Ok'
                        actionButton2={() => {
                            setModalErrorFinish(false);
                            props.history.push("/pesquisarcidadao")
                        }}
                    />
                    
                    <ModalWarn
                        id='modalWarnalertInvalidSession'
                        appear={modalInvalidSessionError}
                        title = { modalInvalidSessionErrorTitle }
                        text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                        textButton2='Ir para tela inicial'
                        actionButton2={() => { 
                        document.getElementById("btnLogout").click();
                        }}
                    />
                    
                    <GlobalContainer>
                        <MessageAlert cpf={cpf} ident_dig={ident_dig}/>

                        <TitleContainer>
                            <Title> {
                                !ident_dig ? 
                                    'Criar Conta gov.br e Gerar Senha Provisória'
                                :
                                    'Atualizar Conta gov.br e Gerar Senha Provisória'
                            } </Title>
                        </TitleContainer>

                        <SubTitleContainer>
                            <SubTitle> Dados do Cidadão </SubTitle>
                        </SubTitleContainer>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ContentContainer>
                                <ContentContainer1>
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> 
                                            { cpfMask(cpf) }
                                        </Text>
                                    </ContentContainer11>

                                    <ContentContainer12>
                                        <label> Data Nascimento </label>
                                        <Text> 
                                            {birth_date.slice(-2)}/{birth_date.slice(4,6)}/{birth_date.slice(0,4)} 
                                        </Text>
                                    </ContentContainer12>

                                    <ContentContainer12>
                                        <label> Selo </label>
                                        <Text> 
                                            {selo_conta ? selo_conta : 'Não possui'}
                                        </Text>
                                    </ContentContainer12>
                                </ContentContainer1>

                                <ContentContainer2>
                                    <ContentContainer21>
                                        <label> Nome </label>
                                        <Text> {social_name ? social_name : name} </Text>
                                    </ContentContainer21>

                                    <ContentContainer22>
                                        <label> Nome da Mãe </label>
                                        <Text> {mothers_name} </Text>
                                    </ContentContainer22>
                                </ContentContainer2>
                            </ContentContainer>

                            {person === "procurador" && 
                                <>
                                    <SubTitleContainer>
                                        <SubTitle>
                                            Contatos relacionados
                                        </SubTitle>
                                    </SubTitleContainer>

                                    <DescContainer>
                                        <TextDesc>
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img
                                                    src={InfoIcon}
                                                    style = {{ paddingBottom: '5px', marginRight: '9px' }}
                                                    alt="infoicon"
                                                />
                                            </i>
                                            Os contatos informados serão utilizados para o recebimento da senha provisória e para futuras recuperações da Conta gov.br. 
                                        </TextDesc>
                                    </DescContainer>
                                </>
                            }

                            <ContentContainer>
                                <ContentContainer1>
                                    <ContentContainer11>
                                        <div className="br-input">
                                            <label htmlFor="email">E-mail</label>
                                            <input
                                                ref={register({ 
                                                    required: false, 
                                                    pattern: {value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: "Digite um e-mail válido."}
                                                })} 
                                                id="email"
                                                name='email'
                                                type="text" 
                                                placeholder="Digite o email"
                                                defaultValue={email}
                                                onChange={(event) => setCitizen({ ...citizen, email: event.target.value })}
                                            />
                                        </div>
                                        {errors?.email && 
                                            <span className="feedback danger" role="alert">
                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                    <img
                                                        src={FailIcon}
                                                        style = {{ paddingBottom: '5px' }}
                                                        alt="failicon"
                                                    />
                                                </i>
                                                <label>{errors.email.message}</label>
                                            </span>
                                        }
                                    </ContentContainer11>

                                    <ContentContainer12>
                                        <div className="br-input">
                                            <label htmlFor="cell">Celular</label>
                                            <input
                                                id="cell"
                                                name="cell"
                                                placeholder="Digite somente números com o DDD "
                                                type="text"
                                                maxLength="15"
                                                defaultValue={cellMask(cell)}
                                                ref={register({
                                                    required: false,
                                                    minLength: { value: 15, message: 'Número inválido.' },
                                                    maxLength: { value: 15, message: 'Número inválido' }
                                                })}
                                                onChange={(event) => {
                                                    const {value} = event.target
                                                    setCitizen({ ...citizen, cell: removeInputMask(value) })
                                                    event.target.value = cellMask(value)
                                                }}
                                            />
                                        </div>
                                        {errors?.cell &&
                                            <span  className="feedback danger" role="alert">
                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                    <img
                                                        src={FailIcon}
                                                        style = {{ paddingBottom: '5px' }}
                                                        alt="failicon"
                                                    />
                                                </i>
                                                <label>{errors.cell.message}</label>
                                            </span>
                                        }
                                    </ContentContainer12>
                                </ContentContainer1>

                                <ContentContainer2>

                                </ContentContainer2>
                            </ContentContainer>

                            <ContentContainer>
                                <ContentContainer1>

                                </ContentContainer1>


                                <ContentContainer2>
                                    <ContentContainerButtons>
                                        <button className="br-button" type="button"
                                                onClick={setModalCancelVisible}>
                                            Cancelar
                                        </button>

                                        {person === "procurador" &&
                                            <button className="br-button secondary" type="button"
                                                    onClick={() => {
                                                        props.history.push(
                                                            "/dadosprocurador",
                                                            {
                                                                id_attendance: citizen.id_attendance,
                                                                attendent: citizen.attendent,
                                                                person: citizen.person,
                                                                name_attorney: attorney.name_attorney,
                                                                cpf_attorney: attorney.cpf_attorney,
                                                                document_list: citizen.document_list,
                                                                documents: citizen.documents,
                                                                cpf_citizen: citizen.cpf,
                                                                document_type: citizen.document_type,
                                                                last_id_list: citizen.last_id_list
                                                            }
                                                        )
                                                    }}>
                                                Voltar
                                            </button>
                                        }

                                        <button className="br-button primary" type="submit">
                                            Avançar
                                        </button>
                                    </ContentContainerButtons>
                                </ContentContainer2>
                            </ContentContainer>
                        </form>
                    </GlobalContainer>    
                </>
            }
        </>
    );
}