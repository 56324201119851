export const cpfMask = (cpf) => {
    if (!cpf) return;
    var num = cpf.replace(/[^\d]/g, ''); //remove todos os caracteres não numéricos
    var len = num.length; //guarda o número de digitos até o momento

    if (len <= 6) {
        cpf = num.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
    } else if (len <= 9) {
        cpf = num.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
    } else {
        cpf = num.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, "$1.$2.$3-$4");
    }
    return cpf;
}

export const removeInputMask = (cpf) => {
    if (!cpf || cpf === "") {
        return ""
    } else {
        return cpf.replace(/[^\d]+/g, "");
    }
};
 
export const formatDate = (value) => value && `${value.slice(8, 10)}/${value.slice(5, 7)}/${value.slice(0, 4)}`

export const today = () => new Date().toISOString().slice(0, 10);

export const rowspan = {
    setTd : (value, fieldName) => {
        let cont = 0;
        value.forEach(
            (r) => {
                if (cont > 0) {
                    cont--;
                    r.HIDECOL = true;
                    return;
                }
                value.forEach(
                    (s) => {
                        if (r[fieldName] === s[fieldName]) {
                            cont++;
                        }
                    }
                )
                r.ROWSPAN = cont;
                r.HIDECOL = false;
                cont--;
            }
        )
        return value;
    },
    td : (r, value) => (r.ROWSPAN > 1) ? <td rowspan={r.ROWSPAN}>{value}</td> : r.HIDECOL ? "" : <td>{value}</td>
}

export const formatarCEP = (cep) => {
    if ((typeof cep === 'undefined') || (cep === null)) return cep;
    // Deixa apenas o número
    cep = cep.replace(/\D/g, '');
  
    // Aplica a máscara no formato XXXXX-XXX
    if (cep.length > 5) {
      cep = cep.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }
  
    return cep;
  }
  