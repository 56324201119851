import React, { useState, useEffect } from 'react';

import UnitElement from '../../Components/UnitElement'
import { estados } from '../../constants'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AddUnit(props) {

    const [ unit, setUnit ] = useState({
        unitName: '',
        zipcode: "",
        state: '',
        street: '',
        number: '',
        addressComplement: '',
        neighborhood: '',
        city: ''
    });

    const [ stateOptions ] = useState(estados);

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(()=>{
        setUnit( {...unit, organCode: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).codigo_orgao : ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ loadingAdd, setLoadingAdd ] = useState(false);    

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

     // ================================================================================

    const onSubmitUnitData = async (data) => {

        setLoadingAdd(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                codigo_orgao: data.organCode,
                nome_unidade: data.unitName,
                cep: data.zipcode,
                estado: data.state.value,
                logradouro: data.street,
                numero: data.number,
                complemento: data.addressComplement,
                bairro: data.neighborhood,
                cidade: data.city
            }),
        };
         
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/cadastraUnidadeOrgao`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const unit_added = {
                        message: data_request.message
                    }
                    setLoadingAdd(false);
                    
                    props.history.push('gerenciarUnidades', unit_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    data.message = data.messages ? data.messages[0].message : data.message
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cadastraUnidadeOrgao BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
                console.log('Error request to /gestao/cadastraUnidadeOrgao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    };

    const pesquisaCEP = async (cep) => {
        cep = cep.replace(/\D/g, '')

        setLoadingAdd(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cep: cep
            }),
        };
         
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/pesquisaCEP`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    setUnit( {...unit, zipcode: data.zipcode, state: estados.filter(option => option.value === data.state)[0], street: data.street, addressComplement: data.addressComplement, neighborhood: data.neighborhood, city: data.city})
                    setLoadingAdd(false);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    data.message = data.messages ? data.messages[0].message : data.message
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/cadastraUnidadeOrgao BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
                console.log('Error request to /gestao/pesquisaCEP');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <UnitElement
                pageTitle='Cadastrar unidade'
                onSubmitData={onSubmitUnitData}
                pesquisaCEP={pesquisaCEP}
                unit={unit}
                setUnit={setUnit}
                stateOptions={stateOptions}
                loadingAdd={loadingAdd}
                setLoading={setLoadingAdd}
                modalError ={modalError}
                setModalError={setModalError}
                messageError={messageError}
                buttonCancelRoute='/gerenciarUnidades'
                history={props.history}
            />
        </>
    );
}